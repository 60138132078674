//VARIABLES
$main-font: Arial, Helvetica, sans-serif;
$main-font-size:2rem ;
$main-color: rgb(184, 149, 62);
$contrast-color: black;


// MIXIN PRESETS
@mixin flex-container-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin white-text-black-bg {
  color: $contrast-color;
  background-color: $main-color;
} 
@mixin black-text-white-bg {
   font-family: $main-font;
   color: $main-color;
   background-color: $contrast-color;  
  } 
  * {
    margin: 0;
  }
  body{
    box-sizing: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  /////////////ABOUT///////////////////
  .about{
    h1{
      padding-top: 5%;
      font-size: 3rem;
      color: rgb(184, 149, 62);
    }
  }
  .aboutimg{
    justify-content: center;
  padding-top: 5%;
  width: 74%;
  padding-left: 12%;
  display: flex;
  p{
    font-size: x-large;
    width: 50%;
  }
  .abimg{
    height: 50%;
    width: 50%;
  }
}
  /////////////REGISTER///////////////////
  .register{
    h1{
      padding-top: 5%;
      font-size: 3rem;
      color: rgb(184, 149, 62);
    }
    
  
  p{
    font-size: xx-large;
    width: 50%;
    padding-left: 25%;
  }
  .regimg{
  justify-content: center;
  padding-top: 5%;
  width: 25%;
  padding-left: 12%;
  display: flex;

}
  }
  /////////////SCHEDULE///////////////////
  .schedule{
    h1{
      padding-top: 5%;
      font-size: 3rem;
      color: rgb(184, 149, 62);
    }
  }
  .sched{
    justify-content: space-between;
    padding-top: 5%;
    width: 80%;
    padding-left: 13%;
    display: flex;
    p{
    font-size: x-large;
    width: 50%;
    }
  
}
/////////////COACHES///////////////////
.coach{
  padding-top: 7%;
  background-color: black;
  color: white;
  display: flex;
   padding-left: 5%;
   padding-bottom: 10%;
} 
.avatar-image {
  height: 25.5rem;
  width: 25.5rem;
  border-radius: 50%;
  padding-left: 10%;
}
/////////////ROSTER///////////////////

.roster{
  padding-top: 7%;
  background-color: black;
  color: white;
  display: flex;
   padding-left: 5%;
   padding-bottom: 10%;
   justify-content: center;
} 
.avatar-image {
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  padding-top: 5%;

}
  /////////////// BAR ////////////////////////
  .bar{
  padding: 5% 5% 5%;
  background-color: black;
  .bttn{
  padding: 2% 7% 2%;
  background-color: rgb(184, 149, 62);
}
button{
  padding-left: 5%;
}
}
/////////////// HOME IMAGES ////////////////////////
.homeimg{
  display: flex;
  justify-content: center;
  padding: 5% 5% 5%;

}
.left{
  color:black;
  text-shadow: 2px 2px 2px red;
  font-size: 3rem;
  justify-content: center;
  text-align: center;
}
.homeimg1{
  width: 35%;
  
}
.homeimg2{
  width: 35%;
  padding-left: 5%;

}
/////////////// DASH ////////////////////////
.dashp, p{
  font-size: large;
}
///////////////FOOTER ////////////////////////
.footer img{
  width: 20%;
  height: 20%;
  padding-top: 0;
  
}
.footer {
  padding-top: 10%;
  padding-bottom: 10%;
 text-align: center;
 font-size: 2rem;
 padding-top: 25%;
 color: black;

}
////////////////////// HOME ///////////////////////
a:hover {
  padding: 1% 1% 1%;
  text-shadow: 2px 2px 2px black;
  font-size: 2rem;
}
.here:hover {
  padding: 1% 1% 1%;
  text-shadow: 12px 12px 12px black;
}
.home{
  background-image: url('../src/assets/dashbg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
}
.home h1{
  font-size: 5rem;
  padding-top: 15%;
  padding-bottom: 5%;
  color: rgb(184, 149, 62);
  text-shadow: 2px 2px 2px black;
  
}
.home p{
  font-size: 4rem;
  color: rgb(184, 149, 62);
  text-shadow: 2px 2px 2px black;
  text-decoration: none;
  padding-left: 1%;
  padding-right: 1%;
}
.home h2{
  font-size: 3rem;
  padding-bottom: 20%;
}
.auth-options {
  display: flex;
  align-items: center; 
  div{
    margin-right: 1.5rem;
    cursor: pointer;
  } &:hover {
    text-decoration: underline;
  }
}
///////////// SECTION ////////////////////////////////
section{
  color: black;
  font-size: 2rem;
  text-align: center;
  text-shadow: 2px 2px 2px black;

}
input{
  width: 50%;
  color: black;
  font-size: 2rem;
  padding: 2% 2% 2%;
}
.inp{
  font-size: 2rem;
  color: black;
  background-color: red;
  padding: 2% 2% 2%;
}
///////////// IMG ////////////////////////////////
.home2{
  text-align: center;
  background-color: red;
}
.home3 img{
  width: 50%;
}
.home3{
  width: 100%;
  display: flex;
}
/////////////NAV ////////////////////////////////

/////////////NAV ////////////////////////////////
.nav {
  @include white-text-black-bg;
  @include flex-container-centered;
  a {
    @include white-text-black-bg;
    text-decoration: none;
    font-family: $main-font;
    div {
      margin: 1rem;
      font-size: large;
    }
  }
}
/////////////NAV 2 ////////////////////////////////
.nav2 {
  @include white-text-black-bg;
  @include flex-container-centered;
  a {
    @include white-text-black-bg;
    text-decoration: none;
    font-family: $main-font;
    div {
      margin: 1rem;
      font-size: large;
    }
  }
}
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////

@media (max-width: 768px){
  .nav {
    display: block;
    font-size: $main-font-size;
  }
  body{
    background-color: rgb(184, 149, 62);
  }
  /////////////ABOUT///////////////////
  .about{
    padding-bottom: 10%;

    h1{
      padding-top: 5%;
      font-size: 2rem;
      color: black;
    }
  }
  .aboutimg{
    justify-content: center;
  padding-top: 5%;
  width: 100%;
  height: 100%;
  padding-left: 0;
  display: block;
  p{
    font-size: x-large;
    width: 75%;
    padding-left: 10%;
  }
  .abimg{
    height: 100%;
    width: 100%;
  }
  }
  /////////////REGISTER///////////////////
  .register{
    h1{
      padding-top: 5%;
      font-size: 2rem;
      color: black;
    }
    
  
  p{
    font-size: x-large;
    width: 75%;
    padding-left: 12%;
  }
  .rgimg{
  justify-content: center;
  padding-top: 5%;
  padding-left: 0;
  display: block;
  
  }
  }
  /////////////SCHEDULE///////////////////
  .schedule{
    justify-content: center;
    h1{
      padding-top: 5%;
      font-size: 2rem;
      color: black;
    }
  }
  .sched{
    padding-top: 5%;
    width: 80%;
    padding-left: 13%;
    display: block;
    p{
    font-size: large;
    padding-left: 25%;
    }
  
  }
  /////////////COACHES///////////////////
  .coach{
  padding-top: 7%;
  background-color: black;
  color: white;
  display: block;
   padding-left: 0;
   padding-bottom: 10%;
  } 
  .avatar-image {
  height: 25.5rem;
  width: 100%;
  border-radius: 50%;
  padding-left: 0;
  }
  /////////////ROSTER///////////////////

.roster{
  padding-top: 7%;
  background-color: black;
  color: white;
  display: flex;
   padding-left: 5%;
   padding-bottom: 10%;
   justify-content: center;
} 
.avatar-image {
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  padding-top: 5%;

}
  /////////////// BAR ////////////////////////
  .bar{
  padding: 5% 5% 5%;
  background-color: black;
  .bttn{
  padding: 2% 7% 2%;
  background-color: rgb(184, 149, 62);
  }
  button{
  padding-left: 5%;
  }
  }
  

/////////////// HOME IMAGES ////////////////////////
.homeimg{
  display:block;
  justify-content: center;
  padding: 5% 5% 5%;
img
 {
  width: 75%;
  align-items: center;
  justify-content: center;
  align-items: center;
}
.homeimg1, .homeimg2{
  width: 100%;
}
}
.left{
  color:black;
  text-shadow: 2px 2px 2px rgb(184, 149, 62);;
  font-size: 2rem;
  justify-content: center;
  text-align: center;
}
.left h1{
  font-size: 2rem;
}
.homeimg1{
  width: 100%;

}
.homeimg2{
  width: 100%;
  padding-left: 0;

}
/////////////// DASH ////////////////////////
.dashp, p{
  font-size: large;
}
///////////////FOOTER ////////////////////////
.footer img{
  width: 20%;
  height: 20%;
  padding-top: 0;

}
.footer {
 text-align: center;
 font-size: 2rem;
 padding-top: 25%;
 color: white;

}
////////////////////// HOME ///////////////////////
 a:hover {
 padding: 1% 1% 1%;
 text-shadow: 2px 2px 2px black;
  font-size: 2rem;
 }
.here:hover {
  padding: 1% 1% 1%;
  text-shadow: 12px 12px 12px black;
}
.home{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
}
.home h1{
  font-size: 3rem;
  padding-top: 15%;
  padding-bottom: 5%;
  color: rgb(184, 149, 62);;
  text-shadow: 2px 2px 2px black;

}
.home p{
  font-size: xx-large;
  color: rgb(184, 149, 62);;
  text-shadow: 2px 2px 2px black;
  text-decoration: none;
  padding-left: 1%;
  padding-right: 1%;
}
.home h2{
  font-size: 2rem;
  padding-bottom: 35%;
}
.auth-options {
  display: block;
  align-items: center; 
  div{
    margin-right: 1.5rem;
    cursor: pointer;
  } &:hover {
    text-decoration: underline;
  }
  }
///////////// SECTION ////////////////////////////////
section{
  color: black;
  font-size: 2rem;
  text-align: center;
  text-shadow: 2px 2px 2px black;

}
input{
  width: 50%;
  color: black;
  font-size: 2rem;
  padding: 2% 2% 2%;
}
.inp{
  font-size: 2rem;
  color: black;
  background-color: rgb(184, 149, 62);;
  padding: 2% 2% 2%;
}
///////////// IMG ////////////////////////////////
.home2{
  text-align: center;
  background-color: rgb(184, 149, 62);;
}
.home3 img{
  width: 100%;
}
.home3{
  width: 100%;
  display: block;
}

}